import { PropsWithChildren } from 'react'
import { TbChevronRight } from 'react-icons/tb'
import Link from 'next/link'
import clsx from 'clsx'
import classes from './BreadcrumbsNavigation.module.scss'

type BreadcrumbsNavigationType = { href?: string; name: string }

export function BreadcrumbsNavigation({
  breadcrumbs = [],
  className,
}: {
  breadcrumbs: BreadcrumbsNavigationType[]
  className?: string
}) {
  return (
    <ul className={clsx(classes.list, className)}>
      {breadcrumbs.map((breadcrumb) => (
        <BreadcrumbsNavigationItem key={breadcrumb.name} href={breadcrumb.href}>
          {breadcrumb.name}
        </BreadcrumbsNavigationItem>
      ))}
    </ul>
  )
}

function BreadcrumbsNavigationItem({
  href,
  children,
}: PropsWithChildren<{ href?: string }>) {
  if (!href) return <li className={classes.item}>{children}</li>

  return (
    <li className={classes.item}>
      <Link href={href}>
        <a className={classes.link}>{children}</a>
      </Link>
      <TbChevronRight className={classes.icon} />
    </li>
  )
}

export function isBreadcrumb(item): item is BreadcrumbsNavigationType {
  return !!item.name
}
