import { BookCalendlyButton } from 'routes/components/Shared'
import LoginLink from 'routes/components/Shared/Link/LoginLink'
import IntegrationAppLogo from './logo-integration-app.svg'
import clsx from 'clsx'
import classes from './AdvertiseBlock.module.scss'

export const AdvertiseBlock = () => {
  return (
    <section className={clsx('page__section', classes.section)}>
      <div className={classes.body}>
        <div className={clsx('page__background', classes.background)} />

        <div className={classes.content}>
          <h2 className={classes.title}>
            Get better integration infrastructure for your SaaS product today.
          </h2>

          <div className={classes.actions}>
            <LoginLink className='page__button' type='primary'>
              Sign Up
            </LoginLink>

            <BookCalendlyButton
              className='page__button'
              category='secondary'
              isReversed
            >
              Schedule a Demo
            </BookCalendlyButton>
          </div>
        </div>

        <img
          src={IntegrationAppLogo.src}
          alt='Integration App Logo'
          className={classes.visual}
        />
      </div>
    </section>
  )
}
